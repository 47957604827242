<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="上游编号">
				<el-input class="el_input" v-model="form.out_trade_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="组别编号">
				<el-input class="el_input" v-model="form.group1_num" placeholder="运单组别编号" clearable></el-input>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="车主手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="车主手机" clearable></el-input>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货物名称">
				<el-input class="el_input" v-model="form.cargo_name" placeholder="货物名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算方式">
				<el-select class="el_input" v-model="form.settlement_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="按吨结算" value="1"></el-option>
					<el-option label="按方结算" value="2"></el-option>
					<el-option label="按趟结算" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否回程">
				<el-select class="el_input" v-model="form.is_need_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="需要回程" value="1"></el-option>
					<el-option label="不需回程" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="匹配方式">
				<el-select class="el_input" v-model="form.match_way" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="车主抢单" value="1"></el-option>
					<el-option label="指定后车主接单" value="2"></el-option>
					<el-option label="车主竞价中标" value="3"></el-option>
					<el-option label="车主扫码接单" value="4"></el-option>
					<el-option label="网点线下匹配" value="5"></el-option>
					<el-option label="合作方匹配" value="6"></el-option>
					<el-option label="后台匹配" value="7"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="装货地">
				<el-cascader class="el_input" v-model="form.case_cities" @change="ser_para_case_cities_change" placeholder="装货地省市区" :options="ser_para.cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="到货地">
				<el-cascader class="el_input" v-model="form.aim_cities" @change="ser_para_aim_cities_change" placeholder="到货地省市区" :options="ser_para.cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="添加网点">
				<el-select class="el_input" v-model="form.outlets_num" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="(item,index) in ser_para.outlets_list" :key="index" :label="item.name" :value="item.outlets_num"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="cargo_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<!-- <el-button type="primary" @click="see_trajectory">查看轨迹</el-button> -->
				 <!-- <el-button type="primary" @click="Kilometers">估算距离</el-button> -->
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="运单编号/组别编号/上游单号" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item">(编号) {{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">(组别) {{scope.row.group1_num}}</div>
						<div class="tab_line_item">(上游) {{scope.row.out_trade_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(发货地省市区) {{scope.row.case_prov}} / {{scope.row.case_city}} / {{scope.row.case_county}}</div>
						<div class="tab_line_item">(发&nbsp;货&nbsp;地&nbsp;详情) {{scope.row.case_addr?scope.row.case_addr:'无'}}</div>
						<div class="tab_line_item">(卸货地省市区) {{scope.row.aim_prov}} / {{scope.row.aim_city}} / {{scope.row.aim_county}}</div>
						<div class="tab_line_item">(卸&nbsp;货&nbsp;地&nbsp;详情) {{scope.row.aim_addr?scope.row.aim_addr:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="名称/重量/体积" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">(名称) {{scope.row.cargo_name}}</div>
						<div class="tab_line_item">(重量) {{scope.row.cargo_weight==0?"未设置":scope.row.cargo_weight+"吨"}}</div>
						<div class="tab_line_item">(体积) {{scope.row.cargo_volume==0?"未设置":scope.row.cargo_volume+"方"}}</div>
						<div class="tab_line_item">(距离) {{scope.row.distance}} 公里</div>
					</template>
				</el-table-column>
				<el-table-column label="运费/支付状态" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">运费: {{scope.row.cost_info.freight_total_text}}</div>
						<div class="tab_line_item">已付: {{scope.row.cost_info.has_payed_total_text}}</div>
						<div class="tab_line_item">状态: {{scope.row.payed_status_text}}</div>
						<div class="tab_line_item">操作: <el-button class="btn_left" @click="tord_payed_list(scope.row.truck_tord_num)" size="mini" type="text">支付记录</el-button></div>
					</template>
				</el-table-column>
				<el-table-column label="其他信息" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">结算: {{scope.row.settlement_type_text}}</div>
						<div class="tab_line_item">回程: {{scope.row.is_need_return_text}}</div>
						<div class="tab_line_item">匹配: {{scope.row.match_way_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货主信息" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">(姓名) {{scope.row.shipper_info.name}}</div>
						<div class="tab_line_item">(电话) {{scope.row.shipper_info.tel}}</div>
						<div class="tab_line_item">(公司) {{scope.row.shipper_info.company_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="司机/货车信息" width="180px">
					<template slot-scope="scope">
						<!-- <div class="tab_line_item">车主: {{scope.row.truck_owner_info.name}}/{{scope.row.truck_owner_info.tel}}</div> -->
						<div class="tab_line_item">司机: {{scope.row.driver_info.name}}/{{scope.row.driver_info.tel}}</div>
						<div class="tab_line_item">车牌: {{scope.row.truck_info.truck_plate_num}}</div>
						<div class="tab_line_item">类型: {{scope.row.truck_info.truck_type_name_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作网点" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">(姓名) {{scope.row.outlets_info.name?scope.row.outlets_info.name:'无'}}</div>
						<div class="tab_line_item">(电话) {{scope.row.outlets_info.tel?scope.row.outlets_info.tel:'无'}}</div>
						<div class="tab_line_item">(网点) {{scope.row.outlets_info.outlets_name?scope.row.outlets_info.outlets_name:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单状态/时间信息" width="172px">
					<template slot-scope="scope">
						<div class="tab_line_item">(状态){{scope.row.status_text}}</div>
						<div class="tab_line_item">(添加){{scope.row.creat_time_text}}</div>
						<div class="tab_line_item">(发车){{scope.row.start_time_text}}</div>
						<div class="tab_line_item">(到货){{scope.row.arrived_time_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column fixed="right" label="操作" width="48">
					<template slot-scope="scope">
						<!-- <el-button class="btn_left" @click="tord_cancel(scope.row)" size="mini" type="text">取消</el-button> -->
						<el-button class="btn_left" @click="voucher_show(scope.row.truck_tord_num)" size="mini" type="text">凭证</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination
			class="margin-top-xl"
			@size-change="page_size_change"
			@current-change="page_current_change"
			:current-page="page.p"
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num"
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper"
		>
		</el-pagination>

		<!-- 凭证界面 -->
		<voucher :truck_tord_num="voucher.truck_tord_num" :is_show="voucher.is_show"></voucher>


		<!-- 支付记录界面 -->
		<payedlist2 :truck_tord_num="payedlist.truck_tord_num" :is_show="payedlist.is_show"></payedlist2>


	</div>
</template>

<script>
	import {regionData,CodeToText} from 'element-china-area-data'
	import truck_type_handle from '@/my/_truck_type_handle.js'
	import voucher from '../voucher/voucher_before_end.vue'
	import payedlist2 from '../finance/tord_pay_list_window.vue'
	export default {
		components:{
			payedlist2,
			voucher
		},
		data() {
			return {

				//搜索条件参数
				ser_para:{
					cargo_type:[],//货物类型
					cities:regionData,//省市区数据
				},

				//搜索条件
				form: {
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游编号
					group1_num:'',//组别编号
					truck_owner_tel:'',//车主手机号
					driver_tel:'',//司机手机号
					truck_plate_num:'',//车牌号
					cargo_name:'',//货物名称
					settlement_type:'',//结算方式
					is_need_return:'',//是否回程
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区

					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县
					outlets_num:'',//网点编号
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//凭证相关
				voucher:{
					is_show:0,
					truck_tord_num:''
				},

				//支付记录
				payedlist:{
					is_show:0,
					truck_tord_num:''
				}
			}
		},
		created() {

			//读取网点列表
			this.get_outlets_list();

			//读取货源
			this.get_page_data()
		},
		methods: {
			    //查询公里数
			Kilometers() {
			var _this = this;;
			this.list.data.forEach((item, index) => {
				var start_cit = item.case_city
				var end_city = item.aim_city
				var start = item.case_city + item.case_county + item.case_addr;
				var end = item.aim_city + item.aim_county + item.aim_addr;
				var start_province = item.case_prov + item.case_city +item.case_county;
				var end_province = item.aim_prov+item.aim_city+item.aim_county;
				var driving = new AMap.Driving({});
				// 高德地图api 查询公里数
				driving.search(
				[
					{ keyword: start_province, city: start },
					{ keyword: end_province, city: end },
				],
				function (status, result) {
					if(start_cit == end_city && result.routes[0].distance / 1000 > 400){
					_this.list.data[index].distance = 0
					}else{
					_this.list.data[index].distance = result.routes[0].distance / 1000;
					}
				}
				);
			});
			},
			//跳转轨迹查询
			see_trajectory(){
				this.$router.push('/pages/transit_real_time/tord/maptrajectory')
				},
			//跳转查看支付记录
			tord_payed_list(truck_tord_num){
				this.payedlist.is_show++;
				this.payedlist.truck_tord_num=truck_tord_num;
			},

			//凭证相关操作
			voucher_show(truck_tord_num){//显示凭证
				this.voucher.is_show++;
				this.voucher.truck_tord_num=truck_tord_num;
			},

			//取消运单
			tord_cancel(item){

				//提示
				this.$my.other.confirm({
					content:"您的操作是'取消运单',请确认",
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_cancel_by_admin',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{

								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					truck_tord_num:'',//货源编号
					driver_tel:'',//车主手机号
					truck_plate_num:'',//车牌号
					cargo_name:'',//货物名称
					cargo_type:'',//货物性质
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县
				}
				this.get_page_data()
			},

			//发货地省市县选择器
			ser_para_case_cities_change(event){
				this.form.case_prov=CodeToText[event[0]];
				this.form.case_city=CodeToText[event[1]];
				this.form.case_county=CodeToText[event[2]];
			},

			//到货地省市县选择器
			ser_para_aim_cities_change(event){
				this.form.aim_prov=CodeToText[event[0]];
				this.form.aim_city=CodeToText[event[1]];
				this.form.aim_county=CodeToText[event[2]];
			},

			//搜索
			cargo_ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){

				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				// if(this.list.loading){
				// 	return;
				// }

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_act_list_admin',
						status:3,//状态(1:待发车,2:运输中,3:已到货)
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//数据空值初始化
							item.truck_info=item.truck_info?item.truck_info:{};
							item.driver_info=item.driver_info?item.driver_info:{};
							item.truck_owner_info=item.truck_owner_info?item.truck_owner_info:{};

							//成交时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//发车时间
							if(item.start_time>0){//已发车
								item.start_time_text=this.$my.other.totime(item.start_time);
							}else item.start_time_text="未发车";

							//到货时间
							if(item.arrived_time>0){//已到货
								item.arrived_time_text=this.$my.other.totime(item.arrived_time);
							}else item.arrived_time_text="未到货";

							//装货时间
							if(item.case_date_start==0&&item.case_date_end!=0){//只有止点

								item.case_date=this.$my.other.totime(item.case_date_end)+"以前";
							}else if(item.case_date_start!=0&&item.case_date_end==0){//只有起点

								item.case_date=this.$my.other.totime(item.case_date_start)+"以后";
							}else if(item.case_date_start!=0&&item.case_date_end!=0){//都有

								item.case_date=this.$my.other.totime(item.case_date_start)+" ~ "+this.$my.other.totime(item.case_date_end);
							}else if(item.case_date_start==0&&item.case_date_end==0){//都没有

								item.case_date="未设置";
							}

							//卸货时间
							if(item.aim_date_start==0&&item.aim_date_end!=0){//只有止点

								item.aim_date=this.$my.other.totime(item.aim_date_end)+"以前";
							}else if(item.aim_date_start!=0&&item.aim_date_end==0){//只有起点

								item.aim_date=this.$my.other.totime(item.aim_date_start)+"以后";
							}else if(item.aim_date_start!=0&&item.aim_date_end!=0){//都有

								item.aim_date=this.$my.other.totime(item.aim_date_start)+" ~ "+this.$my.other.totime(item.aim_date_end);
							}else if(item.aim_date_start==0&&item.aim_date_end==0){//都没有

								item.aim_date="未设置";
							}

							//运单状态
							switch(item.status){
								case '1':item.status_text="待发车";break;
								case '2':item.status_text="运输中";break;
								case '3':item.status_text="已到货";break;
							}

							//结算方式
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}

							//是否回程
							switch(item.is_need_return){
								case '1':item.is_need_return_text="需要回程";break;
								case '2':item.is_need_return_text="不需要回程";break;
							}

							//匹配方式
							switch(item.match_way){
								case '1':item.match_way_text="车主抢单";break;
								case '2':item.match_way_text="指定后车主接单";break;
								case '3':item.match_way_text="车主竞价中标";break;
								case '4':item.match_way_text="车主扫码接单";break;
								case '5':item.match_way_text="网点线下匹配";break;
								case '6':item.match_way_text="合作方匹配";break;
								case '7':item.match_way_text="后台匹配";break;
							}

							//运费
							if(item.cost_info.freight_total){
								item.cost_info.freight_total_text=item.cost_info.freight_total+" 元";
							}else item.cost_info.freight_total_text="未设置";

							//已付总额
							item.cost_info.has_payed_total_text=(parseFloat(item.cost_info.cash_payed)+parseFloat(item.cost_info.fuel_cost_payed)+parseFloat(item.cost_info.road_toll_payed)).toFixed(2)+" 元"

							//匹配方式
							switch(item.payed_status){
								case '1':item.payed_status_text="未支付";break;
								case '2':item.payed_status_text="部分支付";break;
								case '3':item.payed_status_text="支付完毕";break;
							}

							//货车类型
							item.truck_info.truck_type_name_text=item.truck_info.truck_type_name+' / '+item.truck_info.truck_type_name2
						}

						//渲染
						this.list.data=data.list
						this.Kilometers()
					}
				})
			},

			//获取可用搜索参数
			get_ser_para(){

				//尝试从缓存中取出
				let ser_para=this.$my.cache.get('cargo_creat_page_creat_para');

				//如果取出成功
				if(ser_para){
					this.ser_para.cargo_type=[...ser_para.cargo_type]
					this.ser_para.truck_type=[...ser_para.truck_type]
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_creat_para'
					},
					callback:(data)=>{

						//格式化车型数据
						let truck_type=truck_type_handle.for_el_cascader(data.truck_type);

						//缓存之
						this.$my.cache.set({
							cargo_creat_page_creat_para:{
								truck_type:truck_type,
								cargo_type:data.cargo_type
							}
						});

						//置入
						this.ser_para.cargo_type=[...data.cargo_type]
						this.ser_para.truck_type=[...truck_type]
					}
				})
			},

			//显示合同
			show_contract(item){
				this.contract.tord_info=item,
				this.contract.is_show++;
			},

			//读取网点列表
			get_outlets_list(){

				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_business_outlets',
						ctr:'outlets_list_admin',
						num:1000
					},
					callback:(data)=>{

						//渲染
						this.ser_para.outlets_list=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		// height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 134px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>